window.NCoreUtils = window.NCoreUtils || { };
(function () {

  const isInstanceReference = (obj: any): obj is InstanceReference => {
    return obj && 'string' === typeof obj.ncuInstanceKey;
  };

  const instances: Record<string, any> = {};

  const getInstance = (key: string) => {
    const inst = instances[key];
    if (!inst) {
      throw new Error(`invalid instance key ${key}`);
    }
    return inst;
  };

  const nextKey = (function () {
    let supply = 0;
    return () => {
      const uid = supply;
      supply = supply === Number.MAX_SAFE_INTEGER ? 0 : supply + 1;
      return `ncu_interop_${uid}`;
    };
  }());

  const lookup = (instance: any, host: any, index: number, path: string[]): { f: Function, host: any } => {
    if (index < 0) {
      throw new Error('index must be a non-negative number');
    }
    if (index >= path.length) {
      if ('function' !== typeof instance) {
        throw new Error(`${path} is not a function`);
      }
      return { f: instance, host };
    }
    const property = path[index];
    const sub = instance[property];
    if (undefined === sub || null === sub) {
      throw new Error(`unable to resolve ${path.slice(0, index)} while resolving ${path}`);
    }
    return lookup(sub, instance, index + 1, path);
  };

  const throwInvalidInstance = () => {
    throw new Error('instance must be either null/undefined or instance reference');
  };

  const resolveRoot = (instance: any) => null === instance || undefined === instance
  ? window
  : isInstanceReference(instance)
    ? getInstance(instance.ncuInstanceKey)
    : throwInvalidInstance();

  const construct = <T>(constructor: new(...arg: any[]) => T, args: T[]): T => {
    function F(this: T) : void {
        constructor.apply(this, args);
    }
    F.prototype = constructor.prototype;
    return new F();
  };

  window.NCoreUtils.interop = {
    initialize: (instance: any, fn: string, ...args: any[]) => {
      const root = resolveRoot(instance);
      const { f, host } = lookup(root, undefined, 0, fn.split('.'));
      const value = f.apply(host, args);
      const key = nextKey();
      instances[key] = value;
      return { ncuInstanceKey: key };
    },
    initializeNew: (instance: any, fn: string, ...args: any[]) => {
      const root = resolveRoot(instance);
      const { f } = lookup(root, undefined, 0, fn.split('.'));
      const value = construct(<any> f, args);
      const key = nextKey();
      instances[key] = value;
      return { ncuInstanceKey: key };
    },
    initializeAsync: async (instance: any, fn: string, ...args: any[]) => {
      const root = resolveRoot(instance);
      const { f, host } = lookup(root, undefined, 0, fn.split('.'));
      const value = await f.apply(host, args);
      const key = nextKey();
      instances[key] = value;
      return { ncuInstanceKey: key };
    },
    dispose: (ref: InstanceReference) => {
      if (!isInstanceReference(ref)) {
        throw new Error('specified argument is not an instance reference');
      }
      const key = ref.ncuInstanceKey;
      if (instances[key]) {
        delete instances[key];
      }
    },
    loadModuleAsync: async (path: string) => {
      const value = await import(path);
      const key = nextKey();
      instances[key] = value;
      return { ncuInstanceKey: key };
    },
    invokeVoid: (instance: any, fn: string, ...args: any[]) => {
      const root = resolveRoot(instance);
      const { f, host } = lookup(root, undefined, 0, fn.split('.'));
      f.apply(host, args);
    },
    invoke: (instance: any, fn: string, ...args: any[]) => {
      const root = resolveRoot(instance);
      const { f, host } = lookup(root, undefined, 0, fn.split('.'));
      return f.apply(host, args);
    },
    invokeVoidAsync: async (instance: any, fn: string, ...args: any[]) => {
      const root = resolveRoot(instance);
      const { f, host } = lookup(root, undefined, 0, fn.split('.'));
      await f.apply(host, args);
    },
    invokeAsync: async (instance: any, fn: string, ...args: any[]) => {
      const root = resolveRoot(instance);
      const { f, host } = lookup(root, undefined, 0, fn.split('.'));
      return await f.apply(host, args);
    },
    lookup: <T extends object = any> (instance: T) => {
      for (const [key, inst] of Object.entries(instances)) {
        if (instance === inst) {
          return { ncuInstanceKey: key };
        }
      }
      return null;
    },
    get: getInstance,
    track: <T extends object> (instance: T) => {
      const key = nextKey();
      instances[key] = instance;
      return { ncuInstanceKey: key };
    }
  };
}());